<template>
    <st-section :header="title">
        <template #body>
            <st-file
                :maxFileSize="Number(maxFileSize)"
                :acceptedFileTypes="acceptedFileTypes"
                :maxFiles="Number(maxFiles)"
                :viewMode="cantUpload"
                :title="title"
                :value="documents"
                :spinner="validationPending"
                :disabled="validationPending"
                @change="addDocuments"
            >
                <template #buttons>
                    <st-button
                        v-if="showValidateBtn"
                        variant="primary"
                        :callback="validateDocuments"
                        :spinner="validationPending"
                        :disabled="validationPending"
                        class="mt-4 px-6 ml-4 st-btn-upload" >
                        {{ $t('APPLICATION.FORM.VALIDATE_FILE_BUTTON') }}
                    </st-button>
                    <i v-if="validFile" class="fas fa-check-circle valid-file text-success mt-4"></i>
                </template>
            </st-file>
        </template>
    </st-section>
</template>

<script>
import Message from '@/shared/message/message';
import { mapActions, mapGetters } from "vuex";
import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";

// TODO: Move to settings
const MAX_APPLICATION_ATTACHMENTS = 30;
const MAX_FILE_SIZE = 50; // MB
const ALLOWED_FILE_TYPES = [
    "application/zip",
    "application/x-zip-compressed",
];

export default {
    name: "ApplicationGisAttachments",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        maxFiles: {
            type: Number,
            default: MAX_APPLICATION_ATTACHMENTS
        },
        acceptedFileTypes: {
            type: Array,
            default: () => ALLOWED_FILE_TYPES
        },
        maxFileSize: {
            type: Number,
            default: MAX_FILE_SIZE
        },
        title: {
            type: String
        }
    },
    data() {
        return {
            documents: [],
            validationPending: false,
            validFile: false
        }
    },
    computed: {
        ...mapGetters({
            uploadAccessToken: "applications/form/uploadAccessToken",
            currentUser: "auth/currentUser",
            record: "applications/form/record"
        }),
        showValidateBtn() {
            return (this.documents.length && this.documents.some((el) => el instanceof File)) && !this.validFile;
        },
        hasPermissionToOrganisationsApplication() {
            return new ApplicationsPermissions(this.currentUser).readStaff;
        },
        hasPermissionToStaffOwnApplications() {
            return new ApplicationsPermissions(this.currentUser).readOwnStaff;
        },
        hasPermissionToReadStaff() {
            return (
                this.hasPermissionToOrganisationsApplication ||
                this.hasPermissionToStaffOwnApplications
            );
        },
        cantUpload() {
            if (this.hasPermissionToReadStaff || (this.viewMode && this.record?.status !== "review")) {
                return true;
            }

            return false;
        }
    },
    methods: {
        ...mapActions({
            doUpload: "applications/form/upload",
            doValidateFile: "applications/form/doValidateGisFile",
            doUpdate: "applications/form/update",
        }),
        async validateDocuments() {
            this.validationPending = true;

            const formDataObj = new FormData();

            for (let i = 0; i < this.documents.length; i++) {
                const file = this.documents[i];
                if (file instanceof File) {
                    formDataObj.append("files", file);
                }
            }

            const currentUploadedFiles = await this.doUpload({
                files: formDataObj,
                uploadAccessToken: this.uploadAccessToken
            });

            const uploadedFiles = currentUploadedFiles.map(
                (el) => ({
                    file_id: el.id,
                    filename: el.initialFilename,
                    created_date: el.created_date,
                    file_type: "input",
                    created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`
                })
            );

            let gisFilesValid = true;
            const gisFilesLayers = [];
            for (let i=0; i < uploadedFiles.length; i++) {
                const layerData = await this.doValidateFile({
                    application_id: this.record.id,
                    file_id: uploadedFiles[i].file_id
                });

                if(layerData && layerData.layers?.length) {
                    gisFilesLayers.push({
                        file_id: uploadedFiles[i].file_id,
                        filename: uploadedFiles[i].filename,
                        layers: layerData.layers,
                    });
                } else {
                    gisFilesValid = false;
                    break;
                }
            }

            this.validationPending = false;

            if (gisFilesValid) {
                this.validFile = true;

                const previousFiles = this.documents.filter(doc => doc instanceof File === false);
                const previousFileIds = previousFiles.map(v => v.file_id);
                const previousFileLayers = this.fileLayers?.filter(v => previousFileIds.includes(v.file_id)) ?? [];

                await this.doUpdate({
                    id: this.record.id,
                    values: {
                        gis_files: [...previousFiles, ...uploadedFiles],
                        gis_files_layers: [...previousFileLayers, ...gisFilesLayers],
                    },
                });
                this.documents = this.record.gis_files;
                this.fileLayers = this.record.gis_files_layers;
            } else {
                Message.error('APPLICATION.NOTIFIERS.INVALID_GIS_FILE');
            }
        },
        async addDocuments(data) {
            // case when file is deleted!
            if(this.documents.length > data.length) {
                const remainingFileIds = data.map(v => v.file_id);
                await this.doUpdate({
                    id: this.record.id,
                    values: {
                        gis_files: data,
                        gis_files_layers: this.fileLayers?.filter(v => remainingFileIds.includes(v.file_id)) ?? [],
                    },
                });
            }

            this.documents = data;
            this.validFile = false;

            const mappedDocuments = this.documents.map((doc) => doc?.file);
            this.$emit("changeList", mappedDocuments, "gis-attachments");
        }
    },
    created() {
        if (this.record) {
            this.documents = this.record?.gis_files ?? [];
            this.fileLayers = this.record?.gis_files_layers ?? [];
        }
    }
};
</script>

<style scoped>
    .valid-file {
        margin-left: 1rem;
        font-size: 2rem;
        vertical-align: middle;
    }
</style>
